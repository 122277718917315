import { useQuery } from "@tanstack/react-query"
import { GetUserPermissions } from "~/routes/__protected/api/user/permissions"
import { cacheKeys } from "../cacheKeys"
import invariant from "../invariant"

export const useUserPermissions = () => {
  return useQuery<GetUserPermissions>(cacheKeys.userPermissions, async () => {
    const req = await fetch("/api/user/permissions")

    invariant(req.ok)

    return req.json()
  })
}
